import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';

import type { Address } from 'types/common';

import type { ProductionLane } from 'dux/production/types';
import type { ProductionCellPubkey } from 'dux/settings/types';
import type { Factories, ProductionCell } from 'dux/teammates/types';

type Params = {
  factory: string;
  cells?: ProductionCell[];
  lanes: ProductionLane[];
  teammateFactories: Factories;
  shippingAddress?: Address;
  mode?: 'noFilter';
  productionCellPubkey?: ProductionCellPubkey;
};

type FilterParams = (
  cell: ProductionCell,
  lanes: Params['lanes'],
  mode: Params['mode'],
  state?: string
) => ProductionLane[];

const filterParams: FilterParams = (cell, lanes, mode, state) => {
  return filter((lane: ProductionLane) => {
    const matchesProductionCell = lane?.production_cell?.pubkey === cell?.pubkey;

    if (mode === 'noFilter' && state) {
      return matchesProductionCell && includes(state, lane.serving_location.locations);
    }

    return matchesProductionCell;
  }, lanes);
};

const filteredLanesByCategoriesAndFactories = ({
  factory,
  lanes,
  cells,
  shippingAddress,
  mode,
  productionCellPubkey,
}: Params): ProductionLane[] => {
  // For settings
  if (productionCellPubkey) {
    return filter({ production_cell: { pubkey: productionCellPubkey } }, lanes);
  }

  const findCellsBySelectedFactory =
    factory === 'all' ? cells : filter({ production_factory: { pubkey: factory } }, cells);

  const lanesByFactory = map(cell => {
    return filterParams(cell, lanes, mode, shippingAddress?.state);
  }, findCellsBySelectedFactory);

  return orderBy(['name'], ['asc'], flatten(lanesByFactory));
};

export default filteredLanesByCategoriesAndFactories;
