import { createSlice } from '@reduxjs/toolkit';

import findIndex from 'lodash/fp/findIndex';

import { defectItemFromOrderDetail, fetchFormula, fetchOrder, fetchOrders } from './thunks';
import type { OrdersState } from './types';

const initialState: OrdersState = {
  status: 'idle',
  orders: [],
  currentOrder: null,
  error: null,
  formula: null,
  pages: {},
  defectBox: null,
  successMessage: null,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setDefectBox(draftState, action) {
      draftState.defectBox = action.payload;
    },
    resetDefectItems(draftState) {
      draftState.defectBox = null;
    },
    updateOrderGift(draftState, action) {
      const targetGiftIndex = findIndex(
        { variant: { product: { type: 'digital_gift' } } },
        draftState.currentOrder!.items
      );
      draftState.currentOrder!.items[targetGiftIndex] = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // fetchOrders
      .addCase(fetchOrders.pending, draftState => {
        draftState.status = 'pending';
        draftState.orders = [];
        draftState.pages = {};
        draftState.error = null;
      })
      .addCase(fetchOrders.fulfilled, (draftState, action) => {
        const { orders, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.orders = orders;
        draftState.pages = pages;
      })
      .addCase(fetchOrders.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // fetchOrder
      .addCase(fetchOrder.pending, draftState => {
        draftState.status = 'pending';
        draftState.currentOrder = null;
        draftState.error = null;
      })
      .addCase(fetchOrder.fulfilled, (draftState, action) => {
        const order = action.payload;

        draftState.status = 'fulfilled';
        draftState.currentOrder = order;
      })
      .addCase(fetchOrder.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // fetchFormula
      .addCase(fetchFormula.pending, draftState => {
        draftState.status = 'pending';
        draftState.formula = null;
        draftState.error = null;
      })
      .addCase(fetchFormula.fulfilled, (draftState, action) => {
        const formula = action.payload;

        draftState.status = 'fulfilled';
        draftState.formula = formula;
      })
      .addCase(fetchFormula.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // defectItemFromOrderDetail
      .addCase(defectItemFromOrderDetail.pending, draftState => {
        draftState.status = 'pending';
        draftState.error = null;
      })
      .addCase(defectItemFromOrderDetail.fulfilled, (draftState, action) => {
        draftState.status = 'fulfilled';
        draftState.successMessage = action.payload;
      })
      .addCase(defectItemFromOrderDetail.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer, actions } = ordersSlice;

export const { setDefectBox, resetDefectItems, updateOrderGift } = actions;

export default reducer;
