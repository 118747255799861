import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import { getFactory } from 'dux/production/selectors';

import type { SingleFetchedBufferBin } from './types/bufferSpaces-bufferBins-types';
import type { BufferSpace } from './types/bufferSpaces-list-types';
import { getSignedInTeammateProductionCells } from '../teammates/selectors';

type BufferSpacesState = RootState['bufferSpaces'];
type SettingsState = RootState['settings'];

const getBufferSpaces: OmsSelector<BufferSpacesState> = state => state.bufferSpaces;
const getSettings: OmsSelector<SettingsState> = state => state.settings;

export const getBufferSpacesList: OmsSelector<BufferSpacesState['bufferSpaces']> = createSelector(
  getBufferSpaces,
  get('bufferSpaces')
);

export const getFilteredBufferSpacesByFactoriesAndCategories: OmsSelector<
  BufferSpacesState['bufferSpaces']
> = createSelector(
  getBufferSpacesList,
  getFactory,
  getSignedInTeammateProductionCells,
  (_, props) => props?.productionCellPubkey,
  (bufferSpaces, selectedFactory, cells, productionCellPubkey) => {
    // For settings
    if (productionCellPubkey) {
      return filter(
        (bufferSpace: BufferSpace) =>
          some(
            {
              pubkey: productionCellPubkey,
            },
            bufferSpace.production_cells
          ),
        bufferSpaces
      );
    }

    const findCellsBySelectedFactory =
      selectedFactory === 'all'
        ? cells
        : filter({ production_factory: { pubkey: selectedFactory } }, cells);

    const bufferSpacesByFactory = map(cell => {
      return filter(
        (bufferSpace: BufferSpace) =>
          some(
            {
              pubkey: cell.pubkey,
            },
            bufferSpace.production_cells
          ),
        bufferSpaces
      );
    }, findCellsBySelectedFactory);

    return flatten(bufferSpacesByFactory);
  }
);

export const getBufferBins: OmsSelector<BufferSpacesState['bufferBins']> = createSelector(
  getBufferSpaces,
  get('bufferBins')
);

const getBufferBin: OmsSelector<BufferSpacesState['bufferBin']> = createSelector(
  getBufferSpaces,
  get('bufferBin')
);

export const getBufferBinStatus: OmsSelector<SingleFetchedBufferBin['status']> = createSelector(
  getBufferBin,
  get('status.value')
);

export const getStatus: OmsSelector<BufferSpacesState['status']> = createSelector(
  getBufferSpaces,
  get('status')
);

export const getError: OmsSelector<BufferSpacesState['error']> = createSelector(
  getBufferSpaces,
  get('error')
);

export const getPreviousCallParams: OmsSelector<BufferSpacesState['previousCallParams']> =
  createSelector(getBufferSpaces, get('previousCallParams'));

export const getSelectedBufferSpaceName: OmsSelector<BufferSpace['name']> = createSelector(
  getSettings,
  getBufferSpacesList,
  ({ selectedBufferSpace }, bufferSpaces) =>
    get('name', find({ pubkey: selectedBufferSpace }, bufferSpaces)) ?? ''
);

export const getSelectedBufferSpaceNextStatuses: OmsSelector<BufferSpace['next_statuses']> =
  createSelector(getSettings, getBufferSpacesList, ({ selectedBufferSpace }, bufferSpaces) =>
    flow(find({ pubkey: selectedBufferSpace }), get('next_statuses'))(bufferSpaces)
  );

export const getSelectedBufferSpaceSortingColumn: OmsSelector<BufferSpace['sorting_column']> =
  createSelector(getSettings, getBufferSpacesList, ({ selectedBufferSpace }, bufferSpaces) =>
    flow(find({ pubkey: selectedBufferSpace }), get('sorting_column'))(bufferSpaces)
  );

export const getSelectedBufferSpaceSortingOrder: OmsSelector<BufferSpace['sorting_order']> =
  createSelector(getSettings, getBufferSpacesList, ({ selectedBufferSpace }, bufferSpaces) =>
    flow(find({ pubkey: selectedBufferSpace }), get('sorting_order'))(bufferSpaces)
  );
